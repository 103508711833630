<template>
  <v-container fluid class="py-0">
    <v-card class="mb-2">
      <v-card-actions>
        <v-icon @click="close">mdi-arrow-left-circle</v-icon>
        <v-spacer></v-spacer>
        Batch: {{ currentBatch.name }}
        <v-chip class="ma-2">{{ currentBatch.status }}</v-chip>

        Type: {{ currentBatch.extractType }}
        <v-spacer></v-spacer>
        <v-btn color="primary" type="submit" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>

    <v-card>
      <v-col class="pt-2" cols="12">
      <v-card-actions class="pb-0">
        <v-col class="mr-2" cols="2">
          <v-text-field
            v-model="fromDate"
            label="From date"
            v-validate="'required'"
            data-vv-as="fromDate"
            name="fromDate"
            type="date"
            :error-messages="errors.collect('fromDate')"
          ></v-text-field>
        </v-col>
        <v-col class="mr-2" cols="2">
          <v-text-field
            v-model="toDate"
            label="To date"
            v-validate="'required'"
            data-vv-as="toDate"
            name="toDate"
            type="date"
            :error-messages="errors.collect('toDate')"
          ></v-text-field>
        </v-col>
        <v-col class="mr-1" cols="2">
          <v-checkbox v-model="nDISManaged" label="NDIS Managed"></v-checkbox>
        </v-col>
        <v-col class="mr-1" cols="2">
          <v-checkbox v-model="planManaged" label="Plan Managed"></v-checkbox>
        </v-col>
        <v-col class="mr-1" cols="2">
          <v-checkbox
            v-model="isAssignedToBatch"
            @change="applyIsInBatchFilter"
            label="Include items previouly assigned to a batch"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-btn @click="searchItems()">
            <v-icon size="15" class="mr-1" col>mdi-filter</v-icon>Filter
          </v-btn></v-col
        >
        <v-col></v-col>
        <v-col class="mr-2">
         <v-badge :content="itemsCount" :model-value="itemsCount > 0" color="primary">
          <v-icon v-if="!selected.isAdded" size="30" @click="addItemsToBatch(selected)"
            >mdi-cart-plus
          </v-icon>
          </v-badge>
          <v-icon v-if="selected.isAdded" size="30" color="primary"
            >mdi-cart-plus
          </v-icon>
        </v-col>
      </v-card-actions>
      </v-col>
      <v-card-text>
        <v-divider></v-divider>

        <div class="wrapper flex-row">
          <div class="loader" v-if="loading"></div>
        </div>

        <div>
          <div v-if="currentBatch.items">{{ currentBatch.items.length }}</div>
        </div>
        <v-tabs v-model="model" icons-and-text>
          <v-tabs-slider color="rgb(25, 128, 107)"></v-tabs-slider>
          <v-tab :key="1" :href="`#tab-1`">
            {{ errorItems.length }} Error rows
            <v-icon color="red">mdi-alert</v-icon>
          </v-tab>
          <v-tab :key="2" :href="`#tab-2`">
            {{ filteredItems.length }} Valid rows
            <v-icon color="rgb(25, 128, 107)">mdi-check</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="model">
          <v-tab-item :key="1" :value="`tab-1`">
            <v-card flat>
              <v-data-table
                v-model="selected"
                :items="errorItems"
                :dense="true"
                :items-per-page-options="[50]"
                :footer-props="{ 'items-per-page-options': [-1] }"
                show-select
                class="elevation-1"
                :headers="[
                  { text: 'Participant Name', value: 'name' },

                  { text: 'NDIS number', value: 'ndisNumber' },
                  { text: 'NDIS Managed', value: 'ndisManaged' },
                  { text: 'Start date', value: 'startDate' },
                  { text: 'End Date', value: 'endDate' },

                  { text: 'Support Item number', value: 'supportItemNumber' },
                  { text: 'Support Item', value: 'supportItem' },
                  { text: 'Duration/Quantity', value: 'Duration', align: 'end' },

                  { text: 'Price per unit', value: 'price', align: 'end' },
                  { text: 'Price controlled', value: 'pricecontroled' },
                  { text: 'Assigned Batch IDs', value: 'assignedBatches' },
                  { text: '', value: '' },
                ]"
              >
                <template slot="item" slot-scope="props">
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        class="tableCheckbox"
                        v-model="selected"
                        :value="props.item"
                      />
                    </td>
                    <td>{{ props.item.participantName }}</td>
                    <td>{{ props.item.nDISNumber }}</td>
                    <td>
                      {{
                        props.item.lineItem !== undefined
                          ? props.item.lineItem.nDISManaged
                          : ""
                      }}
                    </td>
                    <td>{{ props.item.startDateString }} {{ props.item.startTime }}</td>
                    <td>{{ props.item.endDateString }} {{ props.item.endTime }}</td>
                    <td>
                      {{
                        props.item.lineItem !== undefined
                          ? props.item.lineItem.supportItemNumber
                          : ""
                      }}
                    </td>

                    <td class="text-right">
                      {{ util.toFixed(props.item.duration, 2) }}
                      {{
                        props.item.lineItem !== undefined
                          ? getUnitOfMeasure(props.item.lineItem.unitOfMeasure)
                          : ""
                      }}
                    </td>

                    <td class="text-right">
                      <span
                        v-if="
                          props.item.lineItem !== undefined &&
                          props.item.lineItem.priceControl.toUpperCase() === 'Y'
                        "
                        >{{ util.toFixed(props.item.lineItem.priceLimit, 2) }}</span
                      >
                      <span
                        v-if="
                          props.item.lineItem !== undefined &&
                          props.item.lineItem.priceControl.toUpperCase() !== 'Y'
                        "
                        >{{ util.toFixed(props.item.price, 2) }}</span
                      >
                    </td>

                    <td>
                      {{
                        props.item.lineItem !== undefined
                          ? props.item.lineItem.priceControl
                          : ""
                      }}
                    </td>
                    <td>
                      <v-icon small class="mr-2" @click="editErrorItem(props.item)"
                        >mdi-pencil</v-icon
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" :value="`tab-2`">
            <v-card flat>
              <v-data-table
                v-model="selected"
                :items="filteredItems"
                :dense="true"
                :key="refreshKey"
                :items-per-page-options="[50]"
                :footer-props="{ 'items-per-page-options': [-1] }"
                show-select
                class="elevation-1"
                :headers="[
                  { text: 'Participant Name', value: 'name' },

                  { text: 'NDIS number', value: 'ndisNumber' },
                  { text: 'NDIS Managed', value: 'ndisManaged' },
                  { text: 'Start Date', value: 'startDate' },
                  { text: 'End Date', value: 'endDate' },

                  { text: 'Support Item number', value: 'supportItemNumber' },
                  { text: 'Support Item', value: 'supportItem' },
                  { text: 'Duration/Quantity', value: 'Duration', align: 'end' },

                  { text: 'Price per unit', value: 'price', align: 'end' },
                  { text: 'Price controlled', value: 'pricecontroled' },
                  { text: 'Assigned Batch IDs', value: 'assignedBatches' },
                  { text: '', value: '' },
                ]"
              >
                <template slot="item" slot-scope="props">
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        class="tableCheckbox"
                        v-model="selected"
                        :value="props.item"
                      />
                    </td>
                    <td>{{ props.item.participantName }}</td>
                    <td>{{ props.item.nDISNumber }}</td>
                    <td>{{ props.item.lineItem.nDISManaged }}</td>
                    <td>
                      {{ moment(props.item.startDateString).format("DD-MM-YYYY") }}
                      {{ props.item.startTime }}
                    </td>
                    <td>
                      {{ moment(props.item.endDateString).format("DD-MM-YYYY") }}
                      {{ props.item.endTime }}
                    </td>
                    <td v-if="props.item.lineItem">
                      {{ props.item.lineItem.supportItemNumber }}
                    </td>
                    <td v-if="props.item.lineItem">
                      {{ props.item.lineItem.supportItem }}
                    </td>
                    <td class="text-right">
                      {{
                        props.item.duration !== undefined
                          ? util.toFixed(props.item.duration, 2)
                          : util.toFixed(props.item.quantity, 2)
                      }}
                      {{
                        props.item.lineItem !== undefined
                          ? getUnitOfMeasure(props.item.lineItem.unitOfMeasure)
                          : ""
                      }}
                    </td>

                    <td class="text-right">
                      <span
                        v-if="
                          props.item.lineItem !== undefined &&
                          props.item.lineItem.priceControl === 'y'
                        "
                        >{{
                          util.emptyThen(
                            props.item.lineItem.priceLimit,
                            props.item.lineItem.priceLimitNationalNonRemote
                          )
                        }}</span
                      >

                      <span
                        v-if="
                          props.item.lineItem !== undefined &&
                          props.item.lineItem.priceControl !== 'y'
                        "
                        >{{ util.toFixed(props.item.price, 2) }}</span
                      >
                    </td>

                    <td>{{ props.item.lineItem.priceControl }}</td>
                    <td>
                      <span v-for="b in props.item.assignedBatches" :key="b">
                        <v-btn @click="goToBatch(b)" text>{{ b }}</v-btn>
                      </span>
                    </td>
                    <td class="px-0" style="min-width: 40px">
                      <v-icon small class="mr-2" @click="editValidItem(props.item)"
                        >mdi-pencil</v-icon
                      >
                      <v-icon v-if="props.item.isAdded" small class="mr-2" color="primary"
                        >mdi-check</v-icon
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import * as notesService from "../../services/progressNotesService";
import * as migrationService from "../../services/migrationService";
import * as utils from "../../services/utilsService";
import Vue from "vue";
import VeeValidate from "vee-validate";
import moment from "moment";
import { InvoiceNumber } from "invoice-number";
Vue.use(VeeValidate);
export default {
  name: "app",

  data: () => ({
    items: [],
    refreshKey: false,
    validItems: [],
    errorItems: [],
    filteredItems: [],
    isAssignedToBatch: false,
    isUnselectAll: false,
    isSelected: false,
    selected: [],
    batchItem: {},
    batchItems:[],
    itemsCount: 0,
    progressNotes: [],
    model: "tab-1",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    editedIndex: -1,
    edit: "",
    value: 0,
    vm: {},
    util: null,
    caseId: "",
    goalId: "",
    participantName: "",
    participantFirstname: "",
    participantLastname: "",
    nDISNumber: "",
    dialog: false,
    pagination: {
      rowsPerPage: 100,
    },
    totalItems: 0,
    fromDate: {},
    toDate: {},
    nDISManaged: true,
    planManaged: true,
    loading: false,
  }),
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentBatch() {
      return this.$store.getters.currentBatch;
    },
  },
  mounted() {
    this.util = this.$utils;
    this.init();
  },
  methods: {
    init() {
      this.isAssignedToBatch = false;
      this.fromDate = this.getMonday(new Date());
      this.toDate = this.getFriday(new Date());
      this.searchItems();
    },
    close() {
      this.$router.push("/invoicing-billing/batch");
    },

    goToBatch(batchId) {
      this.$router.push({ path: `/invoicing-billing/batch?id=${batchId}` });
    },
    applyIsInBatchFilter() {
      this.loading = false;
      if (this.isAssignedToBatch) {
        this.filteredItems = this.validItems;
      } else {
        this.filteredItems = this.validItems.filter((x) => {
          return x.assignedBatches === undefined || x.assignedBatches.length <= 0;
        });
      }
      console.log(this.filteredItems);
    },
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

      return moment(new Date(d.setDate(diff))).format("YYYY-MM-DD");
    },
    getFriday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -2 : 5); // adjust when day is sunday
      return moment(new Date(d.setDate(diff))).format("YYYY-MM-DD");
    },
    moment: function (date) {
      return date != undefined ? moment(date) : moment();
    },
    getUnitOfMeasure(key) {
      let value = "";
      switch (key) {
        case "H":
          value = "hours";
          break;
        case "D":
          value = "days";
          break;
        case "WK":
          value = "weeks";
          break;
        case "MON":
          value = "months";
          break;
        case "YR":
          value = "years";
          break;
        case "E":
          value = "items";
          break;
        default:
          break;
      }
      return value;
    },
    noteAdded(data) {
      if (this.editedIndex > -1) {
        if (this.edit == "errorItem") {
          Object.assign(this.errorItems[this.editedIndex], data);
        } else {
          Object.assign(this.filteredItems[this.editedIndex], data);
        }
      }
      this.noteClosed();
      console.log(data);
    },
    noteClosed() {
      this.dialog = false;
      setTimeout(() => {
        this.vm = {};
        this.editedIndex = -1;

        this.$forceUpdate();
      }, 300);
    },
    editErrorItem(item) {
      this.editedIndex = this.errorItems.indexOf(item);
      this.edit = "errorItem";
      this.$store.commit("setCurrentProgressNote", item);
      this.$router.push("/invoicing-billing/notes");
      this.dialog = true;
    },
    editValidItem(item) {
      this.editedIndex = this.filteredItems.indexOf(item);
      this.edit = "validItem";
      this.$store.commit("setCurrentProgressNote", item);
      this.$router.push("/invoicing-billing/notes");
    },
    save() {
      const me = this;

      me.$store.dispatch("setToast", {
        message: "Batch saved",
        color: "primary",
      });

      this.$router.push("/invoicing-billing/batch");
    },
    searchItems() {
      let me = this;
      me.validItems = [];
      me.errorItems = [];
      me.items = [];
      me.model = "tab-2";
      this.loading = true;
      notesService
        .listAllForOrganisation(
          this.currentOrganisation.id,
          this.fromDate,
          this.toDate,
          this.nDISManaged,
          this.planManaged
        )
        .then((results) => {
          results.docs.map((doc) => {
            let item = doc.data();

            item.id = doc.id;

            const validationResult = notesService.validateProgressNote(item);
            if (validationResult.valid) {
              item.errors = [];
              me.validItems.push(item);
            } else {
              item.errors = validationResult.errors;
              me.errorItems.push(item);
              me.model = "tab-1";
            }
          });

          this.applyIsInBatchFilter();
        });
    },

    checkValue(value, valueIfNull) {
      if (value === undefined || value === null) {
        return valueIfNull;
      } else {
        return value;
      }
    },

    async addItemsToBatch(selected) {
      console.log(selected);

      const me = this;
      let invoicesData = [];

      if (selected.length > 0) {
        console.log(selected);

        for (let i = 0; i < selected.length; i++) {
          
            let invoiceRef = migrationService.getInvoiceRefId(me.currentOrganisation.id);
            const snapshot = await invoiceRef.get();

            snapshot.forEach((doc) => {
              let invoices = doc.data();
              invoices.id = doc.id;
              invoicesData.push(invoices);
            });

            let invoiceId = InvoiceNumber.next(invoicesData[invoicesData.length - 1].id);

            migrationService
              .addInvoice(this.currentOrganisation.id, invoiceId)
              .then((result) => {
                
                console.log(`Item added to invoices ${result}`);
              })
              .catch((err) => {
                this.$store.dispatch("setToast", {
                  message: `Error adding to batch ${err}`,
                  color: "error",
                });
                throw err;
              });
            this.addToBatch(selected[i], invoiceId);
            this.itemsCount=i;
         
          if (i == selected.length - 1) {
            
           this.addBatchItems();
            me.$store.dispatch("setToast", {
              message: "Item(s) added to batch",
              color: "primary",
            });
          }
        }
        this.isUnselectAll = !this.isUnselectAll;
        selected.isAdded = true;
        me.refreshKey = !me.refreshKey;
      } else {
        me.$store.dispatch("setToast", {
          message: "Select Items to add to batch",
          color: "warning",
        });
      }
      if (this.isUnselectAll) {
        selected.length = 0;
        this.isUnselectAll = !this.isUnselectAll;
      }
    },

    addToBatch(progressNote, invoiceId) {
      const me = this;

     //console.log(progressNote.id);

      let batchItem = {
        batchId: me.currentBatch.id,
        participantId: progressNote.caseId,
        goalId: progressNote.goalId,
        progressNoteId: progressNote.id,
        providerRegistrationNumber: me.currentOrganisation.providerRegistrationNumber,
        nDISNumber: progressNote.nDISNumber,
        startDate: progressNote.startDate,
        endDate: progressNote.endDate,
        supportItemNumber: progressNote.lineItem.supportItemNumber,
        supportItem: progressNote.lineItem.supportItem,
        duration: this.$utils.emptyThen(progressNote.duration, this.$utils.emptyThen(progressNote.quantity,0)),
        unitOfMeasure: this.getUnitOfMeasure(progressNote.lineItem.unitOfMeasure),
        unitPrice:
          progressNote.lineItem.priceControl === "y"
            ? utils.emptyThen(
                progressNote.priceLimit,
                utils.emptyThen(
                  progressNote.lineItem.priceLimit,
                  progressNote.lineItem.priceLimitNationalNonRemote
                )
              )
            : utils.emptyThen(progressNote.price, 0),
        gSTCode: progressNote.gstType.code,
        claimType: this.$utils.isEmpty(progressNote.claimType)
          ? ""
          : progressNote.claimType.code,
        cancellationReason:
          progressNote.claimType.code == "CANC"
            ? progressNote.cancellationReason.code
            : "",
        participantFirstname: progressNote.participantFirstname,
        participantLastname: progressNote.participantLastname,
        nDISManaged: this.$utils.emptyThen(progressNote.lineItem.nDISManaged, ""),
        contactType: this.$utils.isEmpty(progressNote.contactType)
          ? ""
          : this.$utils.emptyThen(progressNote.contactType.code, ""),
        gstType: this.$utils.isEmpty(progressNote.gstType)
          ? ""
          : this.$utils.emptyThen(progressNote.gstType.code, ""),
        notes: this.$utils.emptyThen(progressNote.notes, ""),
        invoiceNumber: invoiceId,
      };
     //console.log(batchItem.invoiceNumber);
     me.batchItems.push(batchItem);
     me.progressNotes.push(progressNote);
     console.log(me.batchItems);
    },
    addBatchItems()
    {
       const me = this;

      for(let i=0;i<me.batchItems.length;i++)
      {
      migrationService
        .addToBatch(me.currentOrganisation.id, me.batchItems[i], me.progressNotes[i])
        .then((result) => {
          console.log(`Item added to batch ${result}`);
         me.progressNotes[i].isAdded = true;
        })
        .catch((err) => {
          this.$store.dispatch("setToast", {
            message: `Error adding to batch ${err}`,
            color: "error",
          });
          throw err;
        });
      }
    },
  },
};
</script>

<style>
.loader {
  width: 30px;
  height: 30px;
  border: 5px solid #156a59;
  border-bottom-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.flex-row {
  display: flex;
  justify-content: center;
}
.tableCheckbox {
  height: 16.5px;
  width: 16.5px;
  border-radius: 5%;
  vertical-align: middle;
  border: 2px solid #ddd;
  margin: 0px;
  padding: 0px;
  outline: none;
  cursor: pointer;
}
.tableCheckbox:before {
  -webkit-text-stroke: 1px $white;
}
.tableCheckbox:checked {
  accent-color: #156a59;
}
.flex-container {
  flex-wrap: wrap;
}
</style>
